<template id="almacenes">
<div class="page-almacenes">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Administrar Almacenes" class="elevation-1 px-5 py-3">

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="agregar" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_almacen(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="eliminar(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>
              </v-client-table>
              <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Almacen</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? actualizar() : guardar()">
            <v-row>
              <v-col sm="12" md="8" lg="8">
                <v-autocomplete v-model="model.sucursal" :items="sucursales" :hide-no-data="true" :hide-selected="true" item-text="nombre" return-object label="Sucursal" :rules="[requiredObject]">
                </v-autocomplete>
              </v-col>
              <v-col sm="12" md="2" lg="2">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Nombre" v-model="model.nombre" :rules="[rules.required, valida_nombre]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Descripcion" v-model="model.descripcion"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? actualizar() : guardar()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('utilerias.almacenes')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.get_sucursales();
    // this.get_almacenes();
  },
  data() {
    return {
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      sucursales: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Catalogos",
          disabled: true,
          href: ""
        },
        {
          text: "Almacenes",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "nombre",
        "descripcion",
        "sucursal.nombre",
        "estatus",
        "actions"
      ],
      options: {
        filterable: ["nombre", "descripcion", "sucursal.nombre", "estatus"],
        sortable: ["nombre", "descripcion", "sucursal.nombre", "estatus"],

        headings: {
          nombre: "Nombre",
          descripcion: "Descripción",
          "sucursal.nombre": "Sucursal",
          estatus: "Estatus",
          actions: "Operaciones"
        },
      },
      modal: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
      },
      items: []
    }
  },
  methods: {
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/almacenes/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.items = this.items.concat(nuevos);
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    clean_model: function() {
      return {
        _id: "",
        _rev: "",
        nombre: "",
        descripcion: "",
        sucursal: "",
        estatus: "Activo",
      };
    },
    get_sucursales: function() {

      let data = {
        "selector": {
          "estatus": {
            "$eq": "Activo"
          }
        },
        "sort": [
          "nombre"
        ],
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/sucursales/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.sucursales = response.data.docs;
          else
            this.sucursales = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las sucursales.",
            footer: ""
          });
        });
    },
    get_almacenes: function() {
      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {},
        "sort": [
          "nombre"
        ],
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/almacenes/_find/', data)
        .then(response => {
          this.items = response.data.docs;
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    get_almacen: function(id) {
      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + "/almacenes/" + id)
        .then(response => {
          this.model = response.data;
          this.editar();
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    agregar: function() {
      this.model = this.clean_model();
      this.update = false;
      this.modal = true;
    },
    editar: function() {
      this.update = true;
      this.modal = true;
    },
    guardar: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        let data = {
          nombre: this.model.nombre.toString().trim(),
          descripcion: this.model.descripcion.toString().trim(),
          sucursal: this.model.sucursal,
          estatus: this.model.estatus,
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/almacenes/', data)
          .then(response => {
            this.modal = false;
            window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
              color: 'success'
            });
            this.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al guardar el registro.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      }
    },
    actualizar: function() {
      if (this.$refs.form.validate()) {
        let data = this.model;
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/almacenes/' + this.model._id + '?conflicts=true', data)
          .then(response => {
            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
              color: 'success'
            });
            this.items = [];
            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },
    eliminar: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + "/almacenes/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.items = [];
              this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
              window.dialogLoader.hide();
            });

        }

      });
    },
    valida_nombre: function(val) {
      if (val != null) {
        if (this.campo_unico("nombre", val) == false)
          return "El nombre " + val + " ya se encuentra registrado.";
      }
      return true;
    },
    campo_unico: function(campo, valor) {
      var filter = [];
      if (this.update) {
        var filter = this.items.find(e => e[campo].toString().trim() == valor.toString().trim() && e._id != this.model._id);
      } else {
        var filter = this.items.find(e => e[campo].toString().trim() == valor.toString().trim());
      }
      if (filter) {
        return false;
      }
      return true;
    },
    requiredObject: function(val) {
      if (val != null && val.nombre != null && val != "") {
        return true;
      } else
        return "Este campo es requerido";
    },
  }
}
</script>
